import { render, staticRenderFns } from "./adm_search.vue?vue&type=template&id=587aaff8&scoped=true&"
import script from "./adm_search.vue?vue&type=script&lang=js&"
export * from "./adm_search.vue?vue&type=script&lang=js&"
import style0 from "./adm_search.vue?vue&type=style&index=0&id=587aaff8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "587aaff8",
  null
  
)

export default component.exports